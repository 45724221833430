<template>
  <div class="wrapper">
    <admin-title :title="$route.params.id ? '编辑菜单' : '新增菜单'"></admin-title>
    <a-divider />

    <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
      <el-form-item label="上级菜单">
        <MenuTreeSelect v-model="pid"></MenuTreeSelect>
      </el-form-item>
      <el-form-item label="名称" prop="menuName">
        <el-input style="width:400px" v-model="formData.menuName" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-select style="width:400px" v-model="formData.menuType" clearable placeholder="请选择">
          <el-option v-for="item in MENU_TYPE" :key="item.value" :label="item.name" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="路由标识" prop="routeCode">
        <el-input style="width:400px" v-model="formData.routeCode" placeholder="路由标识"></el-input>
      </el-form-item>
      <!-- <el-form-item label="权限标识" prop="perms">
        <el-input style="width:400px" v-model="formData.permCode" placeholder="权限标识"></el-input>
      </el-form-item> -->
      <el-form-item label="排序" prop="sort">
        <el-input style="width:400px" v-model="formData.sort" placeholder="排序"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="" @click="cancel">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { MENU_TYPE } from '@/enum/dict.js'
import MenuTreeSelect from './menu-tree-select.vue'
import { getMenuDetail, editMenu, addMenu, getModuleList } from './api'

export default {
  name: 'Detail',

  components: { MenuTreeSelect },

  data() {
    return {
      pid: 0,
      formData: {
        parentId: 0,
        // linkType: '', //路由类型
        menuName: '', //菜单名称
        menuType: '', //菜单类型
        // module: '', //所属模块
        routeCode: '', //路由标识
        // permCode: '', //权限标识
        sort: 1,
      },
      rules: {
        menuName: [
          { required: true, message: '菜单名称不能为空', trigger: 'blur' }
        ],
        menuType: [
          { required: true, message: '菜单类型不能为空', trigger: 'blur' }
        ],
        routeCode: [
          { required: true, message: '路由标识不能为空', trigger: 'blur' }
        ]
      },
      // 字典
      MENU_TYPE //菜单类型
    }
  },

  computed: {},

  created() {
    this.pid = this.$route.params.menuId
    // if (this.$route.query.menuModule) this.formData.module = this.$route.query.menuModule
    if (this.$route.params.menuId && this.$route.params.parentId === undefined) this.apiQueryMenuDetail()
  },
  mounted() {},
  methods: {
    async save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          // formData中有menuId情况为编辑，无则为新增
          if (this.formData.id) {
            await editMenu(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
            console.log('编辑')
          } else {
            // 新增分两种，$route.params中有menuId时为新增子级，无则为新增一级
            if (this.$route.params.menuId) {
              this.formData.parentId = this.$route.params.menuId
              await addMenu(this.formData).then(() => {
                this.$message.success('新增成功')
                this.$router.back()
              })
              console.log('新增子级')
            } else {
              await addMenu(this.formData).then(() => {
                this.$message.success('新增成功')
                this.$router.back()
              })
              console.log('新增一级')
            }
          }
        } else {
          return false
        }
      })
    },
    cancel() {
      this.$router.back()
    },

    async apiQueryMenuDetail() {
      const res = await getMenuDetail({ id: this.$route.params.menuId })
      this.pid = res.parentId
      this.formData = res
    }
  },
  // activated() {
  //   Object.assign(this._data.formData, this.$options.data().formData)
  //   this.pid = this.$route.params.menuId
  //   // if (this.$route.query.menuModule) this.formData.module = this.$route.query.menuModule
  //   if (this.$route.params.menuId && this.$route.params.parentId === undefined) this.apiQueryMenuDetail()
  // }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 70%;
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
